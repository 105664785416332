<template>
	<PageLayout :title="$t('dashboard.withdrawal')" :subtitle="$t('withdrawal.complete-your-account')">
		<div class="small-width">
			<Loader v-if="formFields.loading" />
			<CompleteAccountForm
				v-if="formFields.data"
				:form-fields="formFields.data"
				step="CompleteAccountBeforeWithdrawalForm"
				@done="onDone"
			/>
		</div>
		<div v-if="formFields.error" class="network-error">
			<FormError :error="$t('errors.network-error')" />
		</div>
	</PageLayout>
</template>

<script>
import Loader from '@/shared/loader/Loader';
import apiClient from '@/api';
import PageLayout from '@/components/page-layout/PageLayout';
import CompleteAccountForm from '@/shared/complete-account-form/CompleteAccountForm';

const FormError = () => import('@/shared/forms/FormError.vue');

export default {
	name: 'BeforeWithdrawalForm',
	components: {
		CompleteAccountForm,
		Loader,
		PageLayout,
		FormError,
	},
	data() {
		return {
			formFields: {
				loading: true,
				data: null,
				error: null,
			},
		};
	},
	mounted() {
		this.getFormFields();
	},
	methods: {
		async getFormFields() {
			try {
				this.formFields.loading = true;
				const { customerFieldConfigurations } = await apiClient.getBeforeWithdrawalFormFields();
				this.formFields.data = customerFieldConfigurations;
			} catch (e) {
				this.formFields.error = e;
			} finally {
				this.formFields.loading = false;
			}
		},
		onDone() {
			this.$router.replace(this.$route.query.redirect ?? '/payments/withdrawal');
		},
	},
};
</script>
